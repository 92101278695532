import styled from "styled-components"

export const BenefitsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 80px 0;
  background: #fff;

  h2 {
    color: #2ecb77;
    text-transform: uppercase;
    font-size: 38px;
    letter-spacing: 1px;
    font-weight: 700;
  }
  p {
    font-size: 18px;
    line-height: 25px;
    color: #021737;  
    width: 100%;

  }

  .stack-ul {
      position: relative;
      margin: 0;

    li {
      display: inline-block;
      width: 49%;
      padding: 12px 0px;
      padding-right: 30px;
      position: relative;
      color: #777;
      font-family: "Montserrat";
      font-size: 16px;
      display: inline-table;
      text-align: left;
      margin: 0;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      vertical-align: top;
      

      p{
        color: #333;
        font-size: 18px;
        font-weight: 600;
        max-width: 300px;
        margin: 0 auto;
      }
      .card{
        min-height: 100px;
        img{
          max-width: 225px;
          margin: 0 auto;
        }
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    
  }
  
  .vertical_center {
    align-self: center;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
`

import React, { useEffect } from "react";
import { Link } from "gatsby"
import {Container, Col, Row} from "react-bootstrap"
import { TextWrapper } from "./Banner.styles"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import CyberSecurity from "../../../images/cyber-security1.png"

const BannerText = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView();
  
    useEffect(() => {
      if (inView) {
        controls.start("visible");
      }
    }, [controls, inView]);

    return(
<TextWrapper>
<Container>
                <Row>
                 

                    <Col lg={12} md={12} sm={12} xs={12}>
                    <p className="white-color">WHY CHOOSE US</p>
                    <motion.div
                    ref={ref}
                    animate={controls}
                    initial="hidden"
                    variants={{visible: { opacity: 1, y: 0, transition: { duration: 1 } },
                    hidden: { opacity: 0, y: 50 }}}>
                        
                        <p>Advanced Matrix provides high quality solutions that enable you to protect your digital assets to move forward with confidence.
                        </p>
                        <p>We deliver services to many sectors and provide expertise that serve the current and future needs of our clients, such as:</p>
                        <p>Financial Institutions -  Aviation - Oil & Gas - Banking - Health care - Education - Government Sectors</p>
              
                    </motion.div>
                    </Col>
                </Row>
                
            </Container>
        
</TextWrapper>
    );
}
export default BannerText;
import React, { useEffect } from "react";
import { Link } from "gatsby"
import {Container, Col, Row, Carousel} from "react-bootstrap"
import { BannerWrapper } from "./Banner.styles"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import LogoIcon from "../../../images/itmatrix-favicon.png"
import BannerText from "./BannerText";
import ServiceCards from "./ServiceCards";
import BannerWhyText from "./BannerWhyText";
import CanvasMatrix from "./CanvasMatrix"
import LayoutImage from "../../../images/web-layout-advance-metrix.png"
import Dna from "../../../images/cyber-security-data-protection-concept.jpg"
import Bulb1 from "../../../images/cybersecurity-think-paper-3.jpg"
import Bulb2 from "../../../images/MainCyberBackground.jpg"




const Banner = () => {
    
    
    const controls = useAnimation();
    const [ref, inView] = useInView();
  
    useEffect(() => {
        controls.start(i => ({
          opacity: 1,
          transition: { delay: i * 1 , repeat: 'Infinity', repeatType: "reverse", duration: 0.5, repeatDelay: 1},
          
        }))
      }, [])

    return(
        <div>
        <BannerWrapper>

        <Container fluid>
                <Row>

                    <Col lg={12} md={12} sm={12} xs={12}>
                    <Carousel fade>
              <Carousel.Item>
                <img
                  className="video-player"
                  height="100%"
                  width="100%"
                  src={Dna}
                >
                  
                </img>
                    <Carousel.Caption>
                    {/* <h3>Start-up to Scale-up</h3> */}
                    <h1>we enable organizations to protect their digital assets and their external attack surface.</h1>
                    <a href="/request-a-demo" className="requestDemo" > Request a Demo </a>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                <img
                  className="video-player"
                  height="100%"
                  width="100%"
                  src={Bulb1}
                >
                  
                </img>

                    <Carousel.Caption>
                    {/* <h3>We Develop World Class Platforms</h3> */}
                    <h1>Be aware about your organization's cyber risk by Identifying the threats in your digital assets.</h1>
                    <a href="/request-a-demo" className="requestDemo" > Request a Demo </a>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                <img
                  className="video-player"
                  height="100%"
                  width="100%"
                  src={Bulb2}
                >                  
                </img>

                    <Carousel.Caption>
                    {/* <h3>Innovation is in Our DNA</h3> */}
                    <h1>Extend monitoring across your 3rd parties, suppliers and <br />vendors.</h1>
                    <a href="/request-a-demo" className="requestDemo" > Request a Demo </a>
                    </Carousel.Caption>
                </Carousel.Item>
                </Carousel>
                    </Col>
                </Row>

                <Row>
                 

                 <Col lg={12} md={12} sm={12} xs={12}>
                 {/* <motion.div class="vertical_center banner-text" 
                 ref={ref}
                 animate={controls}
                 initial="hidden"
                 variants={{visible: { opacity: 1, y: 0, transition: { duration: 1 } },
                 hidden: { opacity: 0, y: 50 }}}>   */}
                                             <br />
                     <img className="center-img" src={LayoutImage}/> 
                     <br />
                     <p className="black">
                     We remain customer first. Our cybersecurity partners offer best in class technologies to protect you in the digital domain. We take it as our core driver to ensure maximum customer value. Through extensive range of products, Advanced Matrix  is uniquely positioned to deliver tangible results that are measurable and result in a positive ROI.Our commitment is to achieve KSA’s vision 2030.
                     </p>                        
                 {/* </motion.div> */}
                 </Col>
             </Row>

                
                
            </Container>
            

           
            
        </BannerWrapper>        
        <ServiceCards/>
        <BannerWhyText />
        </div>
    )
}
export default Banner;
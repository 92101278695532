import styled from "styled-components"

export const ServiceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 80px 0;
  background: #f2f2f2;

  h2 {
    color: #bc0000;
    font-weight: 700;
    font-size: 28px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  p {
    font-size: 18px;
    line-height: 25px;
    color: #777;
    max-width: 895px;
    width: 100%;

    strong {
      color: #bc0000;
    }
  }
  .stack-ul {
      position: relative;
    li {
      display: inline-block;
      width: 33.333%;
      padding: 23px 20px;
      position: relative;
      color: #777;
      font-family: "Montserrat";
      font-size: 16px;
      height: 200px;
      display: inline-table;
      text-align: center;
      margin: 0;
      justify-content: center;
      align-content: center;
      flex-direction: column;

      img{
        max-width: 75px;
        margin: 11px auto;
      }
      p{
        color: #333;
        font-size: 18px;
        font-weight: 600;
        max-width: 300px;
        margin: 0 auto;
      }
      @media screen and (max-width: 767px) {
        width:100%;
      }
    }
    li:after {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      
      z-index: 1;
    }
    li:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
  }
  ul.stack-ul:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 3px solid #f2f2f2;
    border-right: 3px solid #f2f2f2;
}
  .vertical_center {
    align-self: center;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
`

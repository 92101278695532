import styled from "styled-components"
import HeaderBgr from "../../../images/web-image-mg360-03-1.png"
import LayoutImage from "../../../images/web-layout-advance-matrix-iamge.png"

export const BannerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  // background: radial-gradient(circle, rgba(40,201,115,1) 0%, rgba(0,50,131,1) 32%);

  .container-fluid{ 
    width: 100%;
    padding: 0;
  }
  .carousel-caption{
    // bottom: 50%;
    // z-index:10; 
    // transform: translateY(50%);
    top: 25%;
    bottom: auto;
    text-align:left !important;
    width:50%;
    
  }
  .carousel-item {
    height: 850px;
  }

  .requestDemo{
    display: inline-block;
  background: #28c973 !important;
  font-size: 15px;
  color: #fff;
  padding: 8px 20px;
  border-radius: 5px;
  border: 2px solid #28c973;
  margin: 0 10px;
  cursor: pointer;
  text-decoration:none;
  @media (max-width: 500px) {
    margin-bottom:5px;
  }
}  

  canvas{
    z-index: 9 !important;
    position: absolute;
    top: 0;
    bottom: 0;
    right:0; left:0;
  }
  .banner-inner-wrap {
    padding: 150px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
    height: 100%;
    width: 100%;
    background: transparent;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    .banner-text {
      align-items: center;

      img{
        margin: 0 auto;
        width: 100%;
        height: auto;
        //max-width: 300px;
      }
      h1 {
        color: #fff;
        font-size: 95px;
        font-weight: 900;
        letter-spacing: 2px;
        -webkit-text-stroke: #12757c;
        -webkit-text-stroke-width: 5px;
        font-family: "Montserrat", Sans-serif;
        
       
        @media screen and (max-width: 767px) {
          font-size: 25px;
          line-height: 35px;
          text-align: center;
          color: #333;
        }
      }
      h2{
          font-size: 1.7vw;
          line-height: 35px;
          padding-left: 10%;
          padding-right: 10%;
          text-align: center;

          @media screen and (max-width: 767px) {
            font-size: 25px;
            line-height: 35px;
            text-align: center;
            color: #333;
          }
      }
      p {
        color: #fff;
        font-size: 25px;
        letter-spacing: 1px;
        margin-top: 20px;
        font-weight: 550;
        font-family: "Montserrat", Sans-serif;
        width: 80%;
        text-align: left;
        

        @media screen and (max-width: 767px) {
          font-size: 20px;
          margin-top: 50px;
          color: #333;
        }
      }
      @media screen and (max-width: 767px) {
        background: #cccccceb;
        padding: 10px 15px;
        border-radius: 6px;
      }
    }
  }

 
  .vertical_center {
    align-self: center;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }

  .centered {
    position: relative;
    top: 35%;
    left: 50%;
    padding-top:10%;
    padding-left: 12%;
    padding-right: 10%;
    font-size: 1.7vw !important;
    transform: translate(-50%, -50%);
    background-image: url(${LayoutImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: auto;

    @media screen and (max-width: 767px) {
      font-size: 15px !important;
      top: 52%;
      text-align: center;
      color: #fff !important;
    }
  }
  .black{
    color:#000 !important;
    width:80%;
    margin-left:10%;
    font-weight:600;

  }
  .center-img{
    color:#000 !important;
    width:80%;
    margin-left:5%;

  }
  
`

export const ServiceWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 50px;
  h2 {
    font-size: 38px;
    font-weight: 700;
    color: #2ecb77;
    position: relative;
    margin-bottom: 60px;
    margin-top: 40px;    
  }
  .counter-up {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      width: 20%;
      padding: 15px 15px;
      vertical-align: top;
      margin: 0;
      @media (max-width: 1024px) {
        width: 50%;
      }
      @media (max-width: 500px) {
        width: 100%;
      }
      a{
        font-family: "Montserrat";
        text-decoration-line: none;
      }
    }
    li:hover > ul {
      display: block;
    }
  
    li a:hover {
      color: #ee2562;
    }
  }
 
`
export const TextWrapper = styled.div`
background-color: #003283;
padding: 40px 0;
p{
  color: #e3f2ff;
  font-size: 25px;
}
img{
  margin: 0 auto;
  display: block;
}
.white-color {
  font-size: 38px;
  font-weight: 700;
        
}

`
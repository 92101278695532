import React, { useEffect } from "react"
import { Container, Col, Row } from "react-bootstrap"
import { BenefitsWrapper } from "./PartnerSolution.styles"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import Cards from "../../UI/Cards"
import Ctm from "../../../images/ctm360logo.png"
import Dmarc from "../../../images/dmarc360logo.png"
import Pentest from "../../../images/pentest360logo.png"
import Mg from "../../../images/mg360logo.png"


const container = {
  hidden: { opacity: 1, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.4
    }
  }
};
const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};


const PartnerSolution = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <BenefitsWrapper>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <motion.div>
              <motion.h2
              initial="{ y: 20, opacity: 0 }"
              animate="{
                y: 0,
                opacity: 1
              }">PARTNER SOLUTIONS</motion.h2>
              <p>
              Our Partner Solutions  help you with the right solutions to meet your needs. And offer cutting edge technology and secure computing environment.
              </p>
              
              <motion.ul class="stack-ul"
              ref={ref}
              animate={controls}
              variants={container}
              initial="hidden">
                <motion.li variants={item} ><Cards><img src={Ctm}/></Cards></motion.li>
                <motion.li variants={item} ><Cards><img src={Dmarc}/></Cards></motion.li>
                <motion.li variants={item} ><Cards><img src={Pentest}/></Cards> </motion.li>
                <motion.li variants={item} ><Cards><img src={Mg}/></Cards></motion.li>
                
              </motion.ul>
              
            </motion.div>
          </Col>
        </Row>
      </Container>
    </BenefitsWrapper>
  )
}
export default PartnerSolution

import styled from "styled-components"

export const BannerWrapper = styled.div`
  padding: 150px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 40vh;
  height: 100%;
  width: 100%;
  background-color: #e9edf4;

  h1 {
    font-size: 4rem;
    font-weight: 700;
    color: #003283;
    text-transform: none;
    text-align: left;
    font-family: "Montserrat", Sans-serif;

    @media screen and (max-width: 767px) {
      font-size: 40px;
    }
  }

  .vertical_center {
    align-self: center;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
`
export const ShapeWrapper = styled.div`
  .sppb-shape-container.sppb-top-shape {
    top: -1px;
  }
  .sppb-shape-container {
    overflow: hidden;
    position: relative;
    left: 0;
    width: 100%;
    line-height: 0;
    z-index: 2;
  }
  .sppb-shape-container.sppb-top-shape.sppb-shape-flip svg {
    transform: rotateY(180deg) translateX(50%);
    display: block;
    width: 100%;
    position: relative;
    left: 50%;
  }
  svg path {
    fill: #003283;
  }
`

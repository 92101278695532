
import styled from "styled-components"

export const CardsWrapper = styled.div`
.card {
  padding: 3% 10%;
  box-shadow: 0 2px 8px #e9edf4;
  border-radius: 14px;
  background-color: #e9edf4;
  border: none;
  justify-content: center;
}
`
export const FlipWrapper = styled.div`

.flip-container{ 
  position: relative;
  width:100%;
  overflow: hidden;
  height:160px;
  padding: 20px;
    background: lightgrey;
    border-radius: 6px;
    min-height: 160px;
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    box-shadow: 0px 0px 8px 0px rgb(220 220 220);

    img{
      max-width: 100px;
    }
    h4{
      font-weight: 700;
      margin: 0 auto;
      max-width: 260px;
      color: darkblue;
      font-size: 20px;
      text-align: center;
      font-family: "Montserrat";
    }
    
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #a50505;
  overflow: hidden;
  width: 100%;
  height: 0;
  display: flex;
  align-items: center;
  transition: .5s ease;

  .overlay-content{
    padding: 20px;

    ul{
      padding:0;
      margin:0;
      padding-left: 20px;

      li{
        list-style: disc;
        list-style-position: initial;
        color: #e8e8e8;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        padding: 8px 0px;
        display: list-item;
        text-align: left;
        width: 100%;
        vertical-align: top;
        margin: 0;
      }
     
    }
    span {
      position: absolute;
      bottom: 6px;
      right: 12px;
      font-size: 12px;
      color: #d9aaaa;
      letter-spacing: 0.1px;
  }
  }
}
}
`
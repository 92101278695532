import React from 'react'
import {ShapeWrapper} from './PageBanner.styles'

function ShapeDivider() {
    return (
        <ShapeWrapper>
                <div className="sppb-shape-container sppb-top-shape  sppb-shape-flip"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 99" preserveAspectRatio="none">
    <path d="M526.35,17.11C607.41,28.38,687,48.17,768.06,59.5A1149.19,1149.19,0,0,0,1000,68.07V0H0V99C155.18,13.84,347.42-7.77,526.35,17.11Z" transform="translate(0 0.04)"></path>
</svg></div>
        </ShapeWrapper>
    )
}

export default ShapeDivider

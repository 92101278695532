import React, { useEffect } from "react"
import { Container, Col, Row } from "react-bootstrap"
import FlipCard from "../../UI/FlipCard"
import { ServiceWrapper } from "./Banner.styles"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { Link } from "gatsby"
import Image1 from "../../../images/2030.png"
import Image2 from "../../../images/2030.png"
import Image3 from "../../../images/2030.png"
import Image4 from "../../../images/2030.png"
import Image5 from "../../../images/2030.png"
import Image6 from "../../../images/2030.png"
import Image7 from "../../../images/2030.png"
import Image8 from "../../../images/2030.png"

const myItems = [
  {
    id: 0,
    url: "/",
    name: "External Attack Surface Management",
    image: Image1,
    list: "",
  },
  {
    id: 2,
    url: "/",
    name: "Cyber Security Ratings",
    image: Image3,
    list: "Learn More",
  },
  {
    id: 3,
    url: "/",
    name: "Third Party Risk Monitoring",
    image: Image3,
    list: "Learn More",
  },
  {
    id: 1,
    url: "/",
    name: "Brand Protection & Anti Phishing",
    image: Image2,
    list: "Learn More",
  },
  {
    id: 4,
    url: "/",
    name: "Surface, Deep & Dark Web Monitoring",
    image: Image4,
    list: "Learn More",
  },
  {
    id: 5,
    url: "/",
    name: "Cloud Threat Hunting, Investigations & Takedowns",
    image: Image5,
    list: "Learn More",
  },
  {
    id: 6,
    url: "/",
    name: "Data Leakage Protection",
    image: Image6,
    list: "Learn More",
  },
  {
    id: 7,
    url: "/",
    name: "Online Anti-Fraud",
    image: Image7,
    list: "Learn More",
  },
  {
    id: 8,
    url: "/",
    name: "Secure Email Delivery",
    image: Image8,
    list: "Learn More",
  },
  {
    id: 8,
    url: "/",
    name: "CTI Risk Based Environment Hardening",
    image: Image8,
    list: "Learn More",
  },
  
]

const container = {
  hidden: { opacity: 1, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      delayChildren: 0.2,
      staggerChildren: 0.2
    }
  }
};
const item = {
  hidden: { y: 10, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,

    transition: {
      type: "spring", when: "afterChildren", stiffness: 100
    }
  }
};

const ServiceCards = () => {

  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])


  const serviceList = myItems.map(items => (
    <motion.li variants={item}>
      <Link to={items.url}>
        <FlipCard
          key={items.id}
          id={items.id}
          name={items.name}
          image={items.image}
          list={items.list}
        ></FlipCard>
      </Link>
    </motion.li>
  ))

  return (
    <ServiceWrapper>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h2>WHAT WE DO</h2>
            <motion.ul className="counter-up"
            ref={ref}
            animate={controls}
            variants={container}
            initial="hidden">
              {serviceList}
              </motion.ul>
          </Col>
        </Row>
      </Container>
    </ServiceWrapper>
  )
}
export default ServiceCards

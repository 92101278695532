import React, { useEffect } from "react"
import { Container, Col, Row } from "react-bootstrap"
import { ChallengeWrapper } from "./AboutUs.styles"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import Evolution from "../../../images/evolution.png"

const AboutUs = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <ChallengeWrapper>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={{
                visible: { opacity: 1, y: 0, transition: { duration: 1 } },
                hidden: { opacity: 0, y: 60 },
              }}
            >
              
             
            
             <h2>
               About US
             </h2>
             <p>
             Advanced Matrix was established in Saudi Arabia in 1985 (previously known
as PC World), providing IT and network solutions. With the progression of
the IT industry, Advanced Matrix shifted its focus of operations to
Information Security related solutions. Today, Advanced Matrix is one of the
leading Information Security providers in the Kingdom of Saudi Arabia.
             </p>
             <p>
             Advanced Matrix has multiple offices and operation sites in Saudi Arabia,
and a dedicated office in Bahrain to serve the GCC countries. These include
Logo & name training centers, compliance centers, research & development center and
other specific purpose facilities.
             </p>
             <p>
             We believe that our business should address customers business needs first.
Being the technology experts, we take it as our obligation to ensure that our
customers remain completely informed before making a decision.
             </p>
             {/* <img src={Evolution}>
             </img> */}
            </motion.div>
          </Col>
        </Row>
      </Container>
    </ChallengeWrapper>
  )
}
export default AboutUs

import React, {useState} from "react";
import {FlipWrapper} from "./Cards.styles"

export default function FlipCard(props) {
    const [style, setStyle] = useState({height: '0'});
    const listArray = props.list.split(',');
    const resultArray = listArray.map(item => <li>{item}</li>);
    return (

        <FlipWrapper>
            
            <div className="flip-container"
                 onMouseEnter={e => {
                     setStyle({height: '100%'});
                 }}
                 onMouseLeave={e => {
                     setStyle({height: '0'})
                 }}
            >
                {/* <img src={props.image} alt={props.name}></img> */}
             <h4>{props.name}</h4>   
                
            </div>
        </FlipWrapper>
    );
}
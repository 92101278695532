import React, { useEffect } from "react";
import { Link } from "gatsby"
import {Container, Col, Row} from "react-bootstrap"
import { SolutionWrapper } from "./Solution.styles"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"


const Solution = () => {
    
    
    const controls = useAnimation();
    const [ref, inView] = useInView();
  
    useEffect(() => {
      if (inView) {
        controls.start("visible");
      }
    }, [controls, inView]);

    return(
        <SolutionWrapper>           
            <Container>
                <Row>               
                    <Col lg={12} md={12} sm={12} xs={12}>
                    <motion.div 
                    ref={ref}
                    animate={controls}
                    initial="hidden"
                    variants={{visible: { opacity: 1, y: 0, transition: { duration: 1 } },
                    hidden: { opacity: 0, y: 60 }}}>
                       <h2>
                       Our Vision
                        </h2>
                        <p>
                        To be the Leading Provider of Cybersecurity Technologies & Services,
recognized as a qualified architect of creative and responsive solutions.
                        </p>
                    </motion.div>
                    <motion.div 
                    ref={ref}
                    animate={controls}
                    initial="hidden"
                    variants={{visible: { opacity: 1, y: 0, transition: { duration: 1 } },
                    hidden: { opacity: 0, y: 60 }}}>
                       <h2>
                       Our Mission
                        </h2>
                        <p>
                        We seek to deliver cutting edge technology and secure computing
environment to our clients through gap analysis and continuous innovation
of ideas. Our aim is to foster a culture of learning and growth for our
employees and establish a process driven culture of service excellence for
the business.
                        </p>
                    </motion.div>
                    </Col>
                </Row>
                
            </Container>
        </SolutionWrapper>
    )
}
export default Solution;
import styled from "styled-components"

export const VisionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-top: 50px;

  h2 {
    color: #bc0000;
    font-weight: 700;
    font-size: 28px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    line-height: 25px;
    color: #777;
    width: 100%;
    
    strong{
        color: #bc0000;
    }
  }
  .stack-ul {
    li {
      display: inline-block;
      width: 48%;
      padding: 0 20px;
      position: relative;
      margin: 5px 0;
      color: #777;
      font-family: "Montserrat";

      @media screen and (max-width: 767px) {
        width:100%;
      }
    }
    li:before {
      content: "";
      width: 5px;
      height: 5px;
      background: #7d7d7d;
      border-radius: 100%;
      position: absolute;
      left: 0;
      top: 41%;
    }
  }
  .challenge-ul{
      li{
          width:100%;
      }
  }

  .vertical_center {
    align-self: center;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
`

import styled from "styled-components"
import HeaderBgr from "../../../images/web-image-mg360-03-1.png"

export const BannerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: radial-gradient(circle, rgba(40,201,115,1) 0%, rgba(0,50,131,1) 32%);

  canvas{
    z-index: 9 !important;
    position: absolute;
    top: 0;
    bottom: 0;
    right:0; left:0;
  }
  .banner-inner-wrap {
    padding: 150px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    background: transparent;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    .banner-text {
      align-items: center;

      img{
        margin: 0 auto;
    max-width: 300px;
      }
      h1 {
        color: #fff;
        font-size: 95px;
        font-weight: 900;
        letter-spacing: 2px;
        -webkit-text-stroke: #12757c;
        -webkit-text-stroke-width: 5px;
        font-family: "Montserrat", Sans-serif;

       
        @media screen and (max-width: 767px) {
          font-size: 25px;
          line-height: 35px;
          text-align: center;
          color: #333;
        }
      }

      h2{
        
      }

      p {
        color: #fff;
        font-size: 38px;
        letter-spacing: 1px;
        margin-top: 20px;
        font-family: "Montserrat", Sans-serif;
        width: 100%;
        text-align: center;

        @media screen and (max-width: 767px) {
          font-size: 20px;
          margin-top: 50px;
          color: #333;
        }
      }
      @media screen and (max-width: 767px) {
        background: #cccccceb;
        padding: 10px 15px;
        border-radius: 6px;
      }
    }
  }

 
  .vertical_center {
    align-self: center;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
`
export const TextWrapper = styled.div`
background-color: #003283;
padding: 40px 0;
p{
  color: #e3f2ff;
  font-size: 25px;
}
img{
  margin: 0 auto;
  display: block;
}

`
import styled from "styled-components"

export const ChallengeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-top: 50px;
  

  h2 {
    color: #2ecb77;
    text-transform: uppercase;
    font-size: 38px;
    letter-spacing: 1px;
    font-weight: 700;
  }
  p {
    font-size: 18px;
    line-height: 25px;
    color: #021737;  
    width: 100%;

  }


  .vertical_center {
    align-self: center;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
`

import * as React from "react"
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"
import Banner from "../components/Home/Banner/Banner";
import NewBanner from "../components/Home/NewBanner/Banner";
import Solution from "../components/Home/Solution";
import AboutUs from "../components/Home/AboutUs";
import ShapeDivider from "../components/UI/ShapeDivider";
import Vision from "../components/Home/Vision";
import Service from "../components/Home/Service";
import PartnerSolution from "../components/Home/PartnerSolution";

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <NewBanner/>
    <ShapeDivider/>
    <AboutUs/>
    <Solution/>
    <PartnerSolution />
  </Layout>
)

export default IndexPage
